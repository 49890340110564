import React, { useEffect } from "react"
import { graphql, Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import Layout from "../components/Layout"
import MetaData from "../components/meta"
import PostCard from "../components/PostCard"
import ReadingProgress from "../components/ReadingProgress"
import getRelatedPosts from "../utils/getRelatedPosts"
import RenderContent from "../components/RenderContent"

// Import styles
import "../styles/blog-post.scss"
import "highlight.js/styles/github.css"

// Images
import ArrowBlue from "../images/inline-svgs/right-arrow-blue.svg"
import Twitter from "../images/inline-svgs/twitter.svg"
import Linkedin from "../images/inline-svgs/linkedin.svg"
import Email from "../images/inline-svgs/email-dark.svg"

const Post = ({ data, location }) => {
  const post = data.ghostPost
  const allPosts = data.all_blog_posts.edges
  const relatedPosts = getRelatedPosts(post, allPosts)

  useEffect(() => {
    if (window?.location?.hostname === "traefik.io") {
      // Add discourse comments
      window.DiscourseEmbed = {
        discourseUrl: "https://community.traefik.io/",
        discourseEmbedUrl: `https://${window.location.host}/${post.primary_tag.slug}/${post.slug}/`,
        discourseReferrerPolicy: "no-referrer-when-downgrade",
      }
      var d = document.createElement("script")
      d.type = "text/javascript"
      d.async = true
      d.src = `${DiscourseEmbed.discourseUrl}javascripts/embed.js`
      ;(
        document.getElementsByTagName("head")[0] ||
        document.getElementsByTagName("body")[0]
      ).appendChild(d)
    }
  }, [])

  // Reading progress bar
  const target: React.RefObject<HTMLElement> = React.createRef()

  const htmlAst = post.childHtmlRehype && post.childHtmlRehype.htmlAst
  const transformedHtml = post.childHtmlRehype && post.childHtmlRehype.html

  return (
    <Layout>
      <MetaData data={data} location={location} type="article" />
      <ReadingProgress target={target} />

      {/* Add codeinjection scripts */}
      <div
        className="load-external-scripts"
        dangerouslySetInnerHTML={{ __html: post.codeinjection_head }}
      ></div>

      <div className="post-view">
        <article
          className={
            "wrapper-800" +
            (post.feature_image == null ? " no-feature-image" : "")
          }
          ref={target}
        >
          {/* Header */}
          <header className="post__header">
            {/* Back button */}
            <div className="post__primary-tag">
              <Link to={`/${post.primary_tag.slug}/`} className="go-back-link">
                <span className="icon">
                  <ArrowBlue />
                </span>
                <span className="text">{post.primary_tag.name}</span>
              </Link>
            </div>

            {/* Title */}
            <h1 className="post__title">{post.title}</h1>

            <div className="post__meta">
              {/* Authors */}
              <div className="post__authors">
                <div className="avatars">
                  {post.primary_author?.profileImageSharp && (
                    <Link
                      to={`/author/${post.primary_author.slug}/`}
                      key={post.primary_author.slug}
                      className="avatar"
                    >
                      <GatsbyImage
                        image={getImage(post.primary_author?.profileImageSharp)}
                        alt={post.primary_author.name}
                        imgStyle={{
                          borderRadius: "100%",
                        }}
                      />
                    </Link>
                  )}
                </div>
                <div className="names">
                  {post.authors.map((author, index) => (
                    <Link to={`/author/${author.slug}/`} key={author.slug}>
                      {index >= 1 ? ", " : ""}
                      {author.name}
                    </Link>
                  ))}
                </div>
              </div>

              <div className="middot">&middot;</div>

              {/* Tags */}
              <div className="post__tags">
                {post.tags.map((tag, index) => {
                  if (index == 0 || tag.visibility === "internal") return
                  return (
                    <div key={tag.slug}>
                      <Link to={`/tag/${tag.slug}/`}>{tag.name}</Link>
                      <div className="middot">&middot;</div>
                    </div>
                  )
                })}
              </div>

              {/* Date */}
              <div className="post__date">{post.published_at_pretty}</div>
            </div>
          </header>

          {/* Post Content */}
          <div className="post__body">
            <RenderContent
              htmlAst={htmlAst}
              html={transformedHtml || post.html}
            />
          </div>

          {/* Discourse comments */}
          <div
            id="discourse-comments"
            style={{
              marginBottom: "80px",
            }}
          ></div>

          {/* Post Sharing */}
          <div className="post__sharing">
            <div className="post__sharing-title">Share</div>
            <div className="post__sharing-icons">
              <button
                className="post__sharing-icon"
                onClick={() => {
                  window.open(
                    `https://twitter.com/share?text=${post.title}&amp;url=${window.location.href}`,
                    "share-twitter",
                    "width=550,height=235"
                  )
                  return false
                }}
              >
                <span className="icon icon--twitter">
                  <Twitter />
                </span>
              </button>

              <button
                className="post__sharing-icon"
                onClick={() => {
                  window.open(
                    `https://www.linkedin.com/shareArticle?mini=true&url=${window.location.href}&title=${post.title}&summary=${post.excerpt}`,
                    "share-linkedin",
                    "width=550,height=235"
                  )
                  return false
                }}
              >
                <span className="icon icon--linkedin">
                  <Linkedin />
                </span>
              </button>

              <button
                className="post__sharing-icon post__sharing-icon--email"
                onClick={() => {
                  window.location.href = `mailto:?subject=${post.title}&amp;body=Check out this site ${window.location.href}`
                }}
                title="share by email"
              >
                <span className="icon icon--email">
                  <Email />
                </span>
              </button>
            </div>
          </div>
        </article>

        {/* Related Posts */}
        {relatedPosts.length > 0 && (
          <div className="related-posts">
            <div className="wrapper-1200">
              <div className="related-posts__header">Related Posts</div>
              <div className="post-feed">
                {relatedPosts.map(relatedPost => (
                  <PostCard post={relatedPost.node} key={relatedPost.node.id} />
                ))}
              </div>
            </div>
          </div>
        )}

        <div
          className="load-external-scripts"
          dangerouslySetInnerHTML={{ __html: post.codeinjection_foot }}
        ></div>
      </div>
    </Layout>
  )
}

export default Post

export const postQuery = graphql`
  query($slug: String!) {
    ghostPost(slug: { eq: $slug }) {
      childHtmlRehype {
        html
        htmlAst
      }
      ...GhostPostFields
    }

    all_blog_posts: allGhostPost(
      filter: { primary_tag: { slug: { eq: "blog" } } }
      sort: { order: ASC, fields: published_at }
    ) {
      edges {
        node {
          ...GhostPostCardFields
        }
      }
    }
  }
`
